<template>
    <div class="col-md-3 page-sidebar mobile-filter-sidebar d-none d-lg-block">
        <aside>
            <div class="sidebar-modern-inner">
                <div class="block-title sidebar-header pb-0">
                    <h5 class="text-left">{{name}}</h5>
                </div>
                <div class="block-content categories-list  list-filter ">
                    <ul class="list-unstyled">
                        <li class="text-left my-2" v-for="category in categories" v-bind:key="category.slug">
                            <a @click.prevent="setCategoryFilter({name: category.name, slug: category.slug, nameEnglish: category.name_en, parentCategory: name, parentCategoryEnglish: nameEnglish}), setSearchOverlay(true)" :href="category.slug"> {{category.name}} </a>
                        </li>
                    </ul>
                </div>
            </div>
        </aside>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  list: 'categoryNavSide',
  props: {
    categories: Array,
    name: String,
    nameEnglish: String
  },
  methods: {
    ...mapActions(['setCategoryFilter', 'setSearchOverlay'])
  }
}
</script>
<style scoped>
    li a{
       color: #777;
    }
</style>
s
