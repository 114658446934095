<template>
    <div class="col-md-4 col-sm-6 col-lg-4 col-12 pb-4">
        <a :href="route" @click.prevent="setCategoryFilter({name: name, slug: slug, nameEnglish: nameEnglish, parentCategory: parentName, parentCategoryEnglish: parentNameEnglish}), setSearchOverlay(true)">
            <div class="card mx-auto card-event border-0" v-lazy-container="{ selector: 'img' }">
                  <img alt="" class="card-img-top img-responsive " data-holder-rendered="true"
                       :data-src=getAsset(image)>
                <div class="card-body py-1 pl-1">
                    <p class="card-text text-left font-weight-bold py-1 pl-2">{{name}}</p>
                </div>
            </div>
        </a>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'categoryCard',
  props: {
    name: String,
    image: String,
    route: String,
    slug: String,
    nameEnglish: String,
    parentName: String,
    parentNameEnglish: String
  },
  methods: {
    ...mapActions(['setCategoryFilter', 'setSearchOverlay']),
    getAsset (file) {
      try {
        return require('../../assets/Categories/' + file)
      } catch (ex) {
        return require('../../assets/Categories/missing-image.jpg')
      }
    }
  }
}
</script>

<style scoped>
    .card-text{
        color:  #333;
        font-weight: 500;
        font-size: 16px;
    }
    .card-img-top{
      max-width: 100%;
    }
    a:hover {  text-decoration: none;}

</style>
