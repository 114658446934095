<template>
  <div class="px-0">
    <div class="main-container" v-if="categoryFetched">
      <div class="mb-4">
        <h1>{{ categories.name }}</h1>
        <h5 class="offset-md-2 col-12 col-md-8">{{ categories.summary }}</h5>
      </div>
      <div class="container">
        <div class="row mb-4">
          <b-form v-if="!this.settings.advance_search.top" class="category-search mx-auto px-3">
            <b-input-group size="md" class="w-100 w-md-auto">
              <b-form-input  id="nav-search-input" v-model="searchInput" :placeholder="$t('home.search.placeholder')" v-on:keyup.enter="onSubmitSearch()" ></b-form-input>
              <b-input-group-append>
                <b-button variant="primary" size="md" type="submit" @click="onSubmitSearch()">
                  {{ $t('category_search') }}
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form>
        </div>
        <div class="row">
          <!-- this (.mobile-filter-sidebar) part will be position fixed in mobile version -->
          <category-nav-side :categories="category" :name="categories.name" :nameEnglish="categories.name_en"/>
          <!--/.page-side-bar-->
          <div class="col-md-12 col-lg-9 col-sm-12 page-content col-thin-left">
            <div class="category-list">
              <div class="row">
                <category-card v-for="child in category" v-bind:key="child.slug" :name="child.name" :image="child.image_path" :route="'/category/' + child.slug" :slug="child.slug" :nameEnglish="child.name_en" :parentName="categories.name" :parentNameEnglish="categories.name_en"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="pt-5">
      <b-spinner class="" label="Large Spinner"></b-spinner>
    </div>
  </div>
</template>

<script>
import apiService from '../apiService.js'
import categoryCard from '../components/Category/CategoryCard.vue'
import categoryNavSide from '../components/Category/CategoryNavSide.vue'
import config from '../config'
import helper from '../helper'

export default {
  name: 'category',
  components: {
    categoryCard,
    categoryNavSide
  },
  metaInfo () {
    return {
      title: this.categories.meta_data_title,
      description: this.categories.meta_data_body,
      link: [
        {
          rel: 'canonical',
          href: window.location.protocol + '//' + window.location.host + '/category/' + this.$route.params.slug
        }
      ],
      meta: [
        {
          vmid: 'og:title',
          property: 'og:title',
          content: this.categories.meta_data_title
        },
        {
          vmid: 'og:image',
          property: 'og:image',
          content: this.imagePath
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: this.categories.meta_data_body
        },
        {
          vmid: 'og:url',
          property: 'og:url',
          content: window.location.protocol + '//' + window.location.host + '/category/' + this.categories.slug
        },
        {
          vmid: 'og:type',
          property: 'og:type',
          content: 'website'
        },
        {
          vmid: 'og:site_name',
          property: 'og:site_name',
          content: config.anytasks_name
        }
      ]
    }
  },
  data () {
    return {
      settings: JSON.parse(sessionStorage.getItem('settings')),
      category: [],
      categoryFetched: false,
      categoriesSlugs: [],
      categories: [],
      imagePath: '',
      searchInput: ''
    }
  },
  mounted () {
    this.getCategoryAndItsChildren()
  },
  watch: {
    '$route' () {
      this.getCategoryAndItsChildren()
    }
  },
  methods: {
    onSubmitSearch () {
      if (this.searchInput.length > 0) {
        this.searchInput = this.searchInput.replace(/[^\w\s+]/gi, '')
      }
      this.$router.push({ name: 'search', query: { terms: this.searchInput, categories: this.categoriesSlugs } })
    },
    getCategoryAndItsChildren () {
      const data = this.$route.params.slug
      apiService.getCategoryAndItsChildren(data).then((data) => {
        this.categories = data.data['data'][0]

        try {
          this.imagePath = window.location.origin + require('../assets/Categories/' + this.categories.image_path)
        } catch (ex) {
          this.imagePath = window.location.origin + require('../assets/Categories/missing-image.jpg')
        }

        let category = []
        let categoriesSlugs = []
        let dataCategory = data.data['data'][0].child_categories

        dataCategory.forEach(function (item) {
          if (item.gig_count !== 0) {
            category.push(item)
            categoriesSlugs.push(item.slug)
          }
        })
        this.category = category
        this.categoriesSlugs = categoriesSlugs
        this.categoryFetched = true
        helper.reorderMetas()
      }).catch(() => {
      })
    }
  }
}
</script>

<style scoped lang="scss">
h1 {
  color: #0e0e0f;
  font-weight: 700;
}

.category-list {
  background-color: unset;
  box-shadow: unset;
}

@include sm-up {
  .category-search {
    width: 50%;
  }
}

</style>
