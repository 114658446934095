<template>
  <div class="category-task-results mt-2">
    <b-container fluid>
      <b-row>
        <div class="bg-transparent d-flex" v-if="category">
          <Breadcrumb v-if="breadcrumb.length > 0" :breadcrumbList="breadcrumb" class="d-none d-lg-block uppercase" :positionAbsolute="false"/>
        </div>
      </b-row>
      <b-row class="py-1">
        <h1 class="w-100 font-weight-bold px-3 mt-1 pb-0">{{ category.name }}</h1>
        <h5 class="w-75 mx-auto">{{category.summary}}</h5>
        <b-form v-if="!this.settings.advance_search.top" class="category-search mx-auto px-3" @submit.prevent="updateSearchTerms">
          <b-input-group size="md" class="w-100 w-md-auto">
            <b-form-input id="nav-search-input" v-model="searchTermsString" ref="searchInput" :placeholder="$t('home.search.placeholder')"></b-form-input>
            <b-input-group-append>
              <b-button variant="primary" size="md" type="submit">
                {{$t('category_search')}}
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form>
      </b-row>
      <b-row v-if="tasksFetched" class="mt-3">
        <div v-if="!this.settings.advance_search.top && tasksFetched" class="w-100 px-3">
          <b-button class="d-flex d-sm-none bg-white w-100 btn-filter mb-3 align-items-center text-center justify-content-center" id="filter-button" @click="mobileFilterToggle()">
              {{ $t('filter_results_button') }}<i class="fa-solid fa-sliders ml-2" aria-hidden="true"></i>
          </b-button>
        </div>
        <div class="w-100 d-flex flew-row justify-content-between px-2" v-if="tasks.length > 0">
          <div class="d-flex flew-row align-items-center">
            {{ $t('showing_results', {
                'from': ((currentPage - 1) * tasksPerPage) + 1,
                'to': (currentPage * tasksPerPage) > totalTasks ? totalTasks : (currentPage * tasksPerPage),
                'total': totalTasks
            }) }}
          </div>
          <div v-if="!this.settings.advance_search.top" class="d-flex flew-row">
            <b-button class=" bg-white w-100 btn-filter mr-3 mb-0 d-none d-sm-block d-lg-none" @click="mobileFilterToggle()">
                {{ $t('filter_results_button') }}<i class="fa-solid fa-sliders ml-2" aria-hidden="true"></i>
            </b-button>
            <b-button class=" btn btn-filter mr-3 mb-0 d-none d-lg-flex align-items-center" v-on:click='filterBarVisible = !filterBarVisible'>
              {{ $t('filter_results_button') }}<i class="fa-solid fa-sliders ml-2" aria-hidden="true"></i>
            </b-button>
            <b-dropdown class="bg-white mb-0 w-100" id="sort-button" variant="primary" right>
              <template slot="button-content">
                <span>{{ $t('tasks_sort_by') }}</span>: <span class="filter-button-text">{{ sortBySelected.text }}</span>
              </template>
              <b-dropdown-item v-for="option in sortByOptions" class="dropdown-options dropdown-options-font-inherit" :key="option.id" @click="updateSortBy(option)">{{ option.text }}</b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </b-row>
      <b-row>
        <div class="px-2 filter-holder" :class="tasksFetched ? 'open' : 'closed'">
          <TaskFilter ref="taskFilter" class="px-lg-2 task-filter " :class="filterBarVisible ? 'open mb-lg-2 mt-lg-3' : 'closed mt-1'"/>
        </div>
        <b-col cols="12">
          <div v-if="tasksFetched" class="pb-2">
            <div v-if="tasks.length > 0">
              <b-row class="card-holder pb-3">
                <div class="col-lg-3 col-md-6 col-sm-6 px-sm-2 col-12 card-item my-2" v-for="(task, index) in tasks" :key="task.hash">
                  <Card :isFeaturedTask="false" :deliveryTime="task.packages[0].delivery" :taskImage="formatMobileUrl(task.assets[0]['path'], task.assets[0].type)" :carouselImage="task" :username="task.user.username" :slug="task.slug"
                        :user_rating="task.user.review_average" :user_count="task.user.review_count" :user_online="task.user.last_active ? isUserOnline(task.user.last_active) : false"
                        :imageIcon="task.user.photo_path" :description="task.title" :rating="task.review_average" :count="task.review_count"
                        :price="settings.service_rate ? task.packages[0].price + (task.packages[0].price * settings.service_rate / 100) : task.packages[0].price"
                        :hash="task.hash" :select="task.favourite" :position="((currentPage - 1) * tasksPerPage) + index + 1" :sellerInfo="task.user"
                        :location="'category-listing-' + category.slug"
                        :primaryCategorySlug="category.slug" :isHeroTask="task.hero"
                        class="mx-auto ml-sm-0 mr-sm-0 w-100"
                  />
                </div>
              </b-row>
              <div v-if="totalTasks > tasksPerPage">
                <Pagination :perPage="tasksPerPage" :currentPage="currentPage" :total="totalTasks" v-on:paginationUpdate="updatePagination" class="mt-3"/>
              </div>
            </div>
            <div v-else>
              <b-row>
                <div class="mx-auto no-saved-tasks my-4 d-flex flex-column">
                  <h3 class="font-weight-bold">{{ $t('search_no_tasks_message')}}</h3>
                  <p class="pb-0 pl-2 mb-3">{{ $t('search_categories_no_tasks_tips_header') }}</p>
                  <b-button class="bg-white btn-filter d-flex align-items-center py-3 px-4 mx-auto" @click="resetFilters()">
                      {{ $t('reset_filters') }} <i class="fa-solid fa-sliders search ml-2" aria-hidden="true"></i>
                  </b-button>
                </div>
              </b-row>
            </div>
          </div>
          <div v-else>
            <b-spinner class="mt-5 mb-2 mb-md-4" large label="Large Spinner"></b-spinner>
            <div class="mb-5">{{ quipText }}</div>
          </div>
        </b-col>
      </b-row>
      <div v-if="this.category.body_content">
        <hr>
        <!-- SEO TEXT -->
        <div v-html="this.$sanitize(this.category.body_content, {
          allowedTags: [ 'h3', 'h5', 'h4', 'p', 'ul', 'li', 'div' ],
                allowedClasses: {
                  'div': [ 'faqs', 'flex-*', 'row', 'mx-*', 'text-*', 'font-weight-*', 'entry', 'm*', 'p*', 'col-*'],
                  'h3': [ 'flex-*', 'mx-*', 'text-*', 'font-weight-*', 'm*'],
                  'h5': [ 'flex-*', 'mx-*', 'text-*', 'font-weight-*', 'm*'],
                  'h4': [ 'text-*', 'font-weight-*', 'm*'],
                  'p': [ 'text-*', 'font-weight-*', 'm*'],
                }
        })"></div>
      </div>
    </b-container>
  </div>
</template>

<script>
import ApiService from '../../apiService'
import Card from '../../components/Task/Card'
import Pagination from '../../components/Global/Pagination'
import config from '../../config'
import helper from '../../helper'
import TaskFilter from '../../components/Global/TaskFilter'
import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb'

export default {
  name: 'CategoryTaskResults',
  components: {
    Breadcrumb,
    Pagination,
    Card,
    TaskFilter
  },
  created () {
    this.setCategory()
    window.addEventListener('scroll', helper.pauseVideoOnScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', helper.pauseVideoOnScroll)
  },
  metaInfo () {
    return {
      title: this.category.meta_data_title,
      description: this.category.meta_data_body,
      link: [
        {
          rel: 'canonical',
          href: window.location.protocol + '//' + window.location.host + '/category/' + this.category.slug + '?per_page=' + this.tasksPerPage + '&page=' + this.currentPage + '&sort=' + this.sortBySelected.value
        }
      ],
      meta: [
        {
          vmid: 'og:title',
          property: 'og:title',
          content: this.category.meta_data_title
        },
        {
          vmid: 'og:image',
          property: 'og:image',
          content: this.imagePath
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: this.category.meta_data_body
        },
        {
          vmid: 'og:url',
          property: 'og:url',
          content: window.location.protocol + '//' + window.location.host + '/category/' + this.category.slug
        },
        {
          vmid: 'og:type',
          property: 'og:type',
          content: 'website'
        },
        {
          vmid: 'og:site_name',
          property: 'og:site_name',
          content: config.anytasks_name
        }
      ]
    }
  },
  data () {
    return {
      quipText: null,
      settings: JSON.parse(sessionStorage.getItem('settings')),
      anyTasksName: config.anytasks_name,
      hideFilter: true,
      category: '',
      tasks: [],
      initialLoad: true,
      currentPage: this.getPage(),
      tasksPerPage: this.getTasksPerPage(),
      searchTerms: this.getSearchTerms(),
      searchTermsString: this.getSearchTermsString(),
      totalTasks: '',
      tasksFetched: false,
      sortByOptions: this.getSortByOptions(),
      sortBySelected: this.getSortByOptionSelected(),
      hideModal: true,
      tracking_switch: config.tracking_enabled === 'true',
      breadcrumb: [],
      filterBarVisible: false
    }
  },
  watch: {
    'category': function () {
      this.dataLayerPush()
    },
    '$route.params.child': function () {
      this.initialLoad = true
      this.currentPage = 1
      this.setCategory()
    },
    '$route.query': function (to, from) {
      if (!this.initialLoad && Object.keys(from).length > 0 && to !== from) {
        if (this.$route.query.page && this.$route.query.page.toString() !== this.currentPage.toString()) {
          this.currentPage = this.$route.query.page || 1
        }

        if (this.$route.query.sort !== this.sortBySelected.value) {
          this.sortBySelected = this.getSortByOptionSelected()
        }

        this.getTasks(false, false)
      }
    }
  },
  methods: {
    dataLayerPush () {
      if (config.google_tag_manager !== '' && config.tracking_enabled === 'true') {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          'event': 'subCategoryView',
          'ecommerce': {
            'subcategory_page': {
              'parent_category': this.category.parent_category.slug,
              'sub_category': this.category.slug
            }
          }
        })
      }
    },
    getSearchTerms () {
      return this.$route.query.terms ? this.$route.query.terms.split(' ') : []
    },
    getSearchTermsString () {
      return this.getSearchTerms().join(' ')
    },
    updateSearchTerms () {
      let params = this.$route.query
      let formData = [
        ['terms', this.searchTermsString.replace(/[^\w\s+]/gi, '')],
        ['page', 1]
      ].reduce((ac, val) => ({ ...ac, [val[0]]: val[1] }), {})

      this.$router.push({ query: Object.fromEntries(Object.entries({ ...params, ...formData }).filter(([_, v]) => v !== '')) }).catch(() => {})
      this.searchTerms = this.getSearchTerms()
      this.currentPage = 1
      this.getTasks(false, false)
    },
    formatMobileUrl (filename, filetype) {
      return helper.formatMobileUrl(filename, filetype)
    },
    setCategory () {
      this.quipText = helper.loadQuip(null)
      this.tasksFetched = false
      this.searchTerms = []
      this.searchTermsString = ''
      let prams = this.$route.params.slug + '/' + this.$route.params.child
      return ApiService.getCategoryAndItsChildren(prams).then(response => {
        this.hideFilter = false
        this.category = response.data.data[0]
        try {
          this.imagePath = window.location.origin + require('../../assets/Categories/' + this.category.image_path)
        } catch (ex) {
          this.imagePath = window.location.origin + require('../../assets/Categories/missing-image.jpg')
        }
        this.getTasks(false)
      }).catch((error) => {
        this.tasksFetched = true
        ApiService.showError(error)
      })
    },
    filterDetail () {
      return this.$refs.taskFilter.getData(true, false)
    },
    getTasks (loadQuip = true, updateHistory = true) {
      if (loadQuip) {
        this.quipText = helper.loadQuip(null)
      }
      this.tasksFetched = false
      window.scrollTo(0, 0)
      let filters = this.filterDetail()
      let urlParams = ApiService.buildGetTaskParameters(this.tasksPerPage, this.currentPage, this.searchTerms, filters.deliveryTime, filters.min, filters.max, filters.rating, filters.languages, [this.category.slug], this.sortBySelected.value)
      if (this.$route.query.gclid) {
        urlParams += '&gclid=' + this.$route.query.gclid
      }
      if (this.$route.query.utm_source) {
        urlParams += '&utm_source=' + this.$route.query.utm_source
      }
      if (this.$route.query.utm_medium) {
        urlParams += '&utm_medium=' + this.$route.query.utm_medium
      }
      if (this.$route.query.utm_campaign) {
        urlParams += '&utm_campaign=' + this.$route.query.utm_campaign
      }

      if (this.$route.query.sort === 'recommended') {
        updateHistory = true
      }

      if (this.initialLoad) {
        this.$router.replace(urlParams.replace('&categories=' + this.category.slug.replace('/', '%2F'), ''))
        this.initialLoad = false
      } else if (updateHistory) {
        history.pushState({}, null, urlParams.replace('&categories=' + this.category.slug.replace('/', '%2F'), ''))
      }

      return ApiService.getTasksWithPrebuildParams(urlParams).then(response => {
        this.tasksFetched = true
        this.totalTasks = response.data.data.pagination.total
        this.tasks = response.data.data.results

        this.breadcrumb = [
          { 'name': this.anyTasksName, 'link': this.$router.resolve({ name: 'home' }).href },
          { 'name': this.category.parent_category.name, 'link': this.$router.resolve({ name: 'category', params: { slug: this.category.parent_category.slug } }).href },
          { 'name': this.category.name, 'link': this.$router.resolve({ name: 'search_category', params: { slug: this.category.parent_category.slug, child: this.category.slug.split('/')[1] } }).href }
        ]
        helper.reorderMetas()
      }).catch((error) => {
        this.tasksFetched = true
        ApiService.showError(error)
      })
    },
    updatePagination (page) {
      this.currentPage = page
      if (this.$route.query.page !== this.currentPage) {
        this.$route.query.page = this.currentPage
        this.getTasks()
      }
    },
    updateSortBy (item) {
      this.sortBySelected = item

      let params = this.$route.query
      let formData = {
        sort: item.value,
        page: 1
      }
      this.$router.push({ query: Object.fromEntries(Object.entries({ ...params, ...formData })) })
    },
    resetFilters () {
      this.$refs.taskFilter.clear('all')
      this.getTasks()
    },
    getPage () {
      if (this.$route.query.page && !isNaN(this.$route.query.page)) {
        return this.$route.query.page
      } else {
        return 1
      }
    },
    getTasksPerPage () {
      if (this.$route.query.per_page && !isNaN(this.$route.query.per_page)) {
        return this.$route.query.per_page
      } else {
        return config.task_search_results_amount_to_show_default
      }
    },
    getSortByOptionSelected () {
      let params = this.$route.query
      if (params.sort) {
        for (let item of this.getSortByOptions()) {
          if (item.value === params.sort) {
            return item
          }
        }
      }
      return { id: 4, text: this.$t('tasks_sort_best_popular'), value: 'popular' }
    },
    getSortByOptions () {
      return [
        { id: 0, text: this.$t('tasks_sort_newest'), value: 'date.desc' },
        { id: 1, text: this.$t('tasks_sort_lowest_price'), value: 'price.asc' },
        { id: 2, text: this.$t('tasks_sort_highest_price'), value: 'price.desc' },
        { id: 3, text: this.$t('tasks_sort_best_reviewed'), value: 'review.desc' },
        { id: 4, text: this.$t('tasks_sort_best_popular'), value: 'popular' },
        { id: 5, text: this.$t('tasks_sort_last_active'), value: 'lastActive' }
      ]
    },
    isUserOnline (date) {
      return helper.isUserOnline(date)
    },
    mobileFilterToggle () {
      this.$refs.taskFilter.displayModal(true)
      this.filterBarVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>

  .filter-holder {
    &.closed {
      overflow: hidden;
      height: 0;
      margin: 0;
      padding: 0;
    }
    &.overflow {
      overflow: auto;
      height: auto;
    }
  }

  .task-filter {
    opacity: 0;
    animation: none;
    &.open {
      opacity: 1;
      transition: all .25s;
      animation: fadeIn .25s ease-in-out;
    }
    &.closed {
      opacity: 0;
      max-height: 0;
      transition: all .25s;
      animation: fadeOut .12s ease-in-out;
      z-index: 0;
    }
  }

  @include lg-up {
    .task-filter {
      &.open {
        max-height: 242px;
      }
    }
  }

  hr {
    margin-top: 4rem;
  }

  @include sm-up {
    #filter-button,
    #sort-button {
      width: auto !important;
    }
    .category-search {
      width: 50%;
    }
  }

  .component-fade-enter-active, .component-fade-leave-active {
    transition: opacity .3s ease;
  }
  .component-fade-enter, .component-fade-leave-to {
    opacity: 0;
  }
  .btn-filter {
    background: white;
    border: 1px solid $task-grey-border;
    color: $brand-denim;
    &:focus {
      box-shadow: none;
    }
  }

</style>

<style lang="scss">

    .faqs {
      p {
        font-size: 14px;
      }
      ul {
        li {
          font-size: 14px;
          list-style-type: disc !important;
        }
      }
    }

    .card-holder {
      display: flex;
      flex-wrap: wrap;
    }
    .card-item {
      display: flex;
    }

    .dropdown-menu {
        z-index: 5 !important;
    }
</style>
